import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import {
  ChatGroup,
  ChatHub,
  ChatRoom,
  MessageBody,
  User
} from "graqhql-gen/graphql";
import { ChatMessage } from "../graqhql-gen/graphql";

interface ChatHubState {
  chatHub: ChatHub[];
}
const initialState: ChatHubState = {
  chatHub: []
};

export const chatHubSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    getChatHub: (state, action: PayloadAction<ChatHub[]>) => {
      if (state.chatHub && action.payload) state.chatHub = action.payload;
    },
    createChatHub: (state, action: PayloadAction<ChatHub>) => {
      if (state.chatHub) state.chatHub.unshift(action.payload);
    },
    addChatGroup: (
      state,
      action: PayloadAction<{ chatHubId: string; chatGroup: ChatGroup }>
    ) => {
      state.chatHub = state.chatHub.map((chatHub) => {
        if (chatHub?.id === action.payload.chatHubId) {
          return {
            ...chatHub,
            chatgroupList: chatHub?.chatgroupList?.concat(
              action.payload.chatGroup
            )
          };
        }
        return chatHub;
      });
    },
    editMessage: (
      state,
      action: PayloadAction<{
        chatHubId: string;
        chatGroupId: string;
        chatRoomId: string;
        messageId: string;
        messageBody: MessageBody;
      }>
    ) => {
      state.chatHub = state.chatHub.map((chatHub) => {
        if (chatHub?.id === action.payload.chatHubId) {
          return {
            ...chatHub,
            chatgroupList: chatHub?.chatgroupList?.map((chatGroup) => {
              if (chatGroup?.id === action.payload.chatGroupId) {
                return {
                  ...chatGroup,
                  chatroomList: chatGroup?.chatroomList?.map((chatRoom) => {
                    if (chatRoom?.id === action.payload.chatRoomId) {
                      return {
                        ...chatRoom,
                        messageList: chatRoom?.messageList?.map((message) => {
                          if (message?.id === action.payload.messageId) {
                            return {
                              ...message,
                              messageBody: action.payload.messageBody
                            };
                          }
                          return message;
                        })
                      };
                    }
                    return chatRoom;
                  })
                };
              }
              return chatGroup;
            })
          };
        }
        return chatHub;
      });
    },

    deleteChatGroup: (
      state,
      action: PayloadAction<{ chatHubId: string; chatGroupId: string }>
    ) => {
      state.chatHub = state.chatHub.map((chatHub) => {
        if (chatHub?.id === action.payload.chatHubId) {
          return {
            ...chatHub,
            chatgroupList: chatHub?.chatgroupList?.filter(
              (chatGroup) => chatGroup?.id !== action.payload.chatGroupId
            )
          };
        }
        return chatHub;
      });
    },
    deleteMessage: (
      state,
      action: PayloadAction<{
        chatHubId: string;
        chatGroupId: string;
        chatRoomId: string;
        messageId: string;
      }>
    ) => {
      state.chatHub = state.chatHub.map((chatHub) => {
        if (chatHub?.id === action.payload.chatHubId) {
          return {
            ...chatHub,
            chatgroupList: chatHub?.chatgroupList?.map((chatGroup) => {
              if (chatGroup?.id === action.payload.chatGroupId) {
                return {
                  ...chatGroup,
                  chatroomList: chatGroup?.chatroomList?.map((chatRoom) => {
                    if (chatRoom?.id === action.payload.chatRoomId) {
                      return {
                        ...chatRoom,
                        messageList: chatRoom?.messageList?.filter(
                          (message) => message?.id !== action.payload.messageId
                        )
                      };
                    }
                    return chatRoom;
                  })
                };
              }
              return chatGroup;
            })
          };
        }
        return chatHub;
      });
    },
    deleteChatHub: (state, action: PayloadAction<string>) => {
      state.chatHub = state.chatHub.filter(
        (chatHub) => chatHub?.id !== action.payload
      );
    },
    createChatRoom: (
      state,
      action: PayloadAction<{
        chatHubId: string;
        chatGroupId: string;
        chatRoom: ChatRoom;
      }>
    ) => {
      state.chatHub = state.chatHub.map((chatHub) => {
        if (chatHub?.id === action.payload.chatHubId) {
          return {
            ...chatHub,
            chatgroupList: chatHub?.chatgroupList?.map((chatGroup) => {
              if (chatGroup?.id === action.payload.chatGroupId) {
                return {
                  ...chatGroup,
                  chatroomList: chatGroup?.chatroomList?.concat(
                    action.payload.chatRoom
                  )
                };
              }
              return chatGroup;
            })
          };
        }
        return chatHub;
      });
    },
    sendMessage: (state, action: PayloadAction<ChatMessage>) => {
      state.chatHub = state.chatHub.map((chatHub) => {
        if (chatHub.id === action.payload?.chatHubId) {
          return {
            ...chatHub,
            chatgroupList: chatHub.chatgroupList?.map((chatGroup) => {
              if (chatGroup?.id === action.payload.chatGroupId) {
                return {
                  ...chatGroup,
                  chatroomList: chatGroup?.chatroomList?.map((chatRoom) => {
                    if (chatRoom?.id === action.payload.chatRoomId) {
                      return {
                        ...chatRoom,
                        messageList: chatRoom?.messageList?.concat(
                          action.payload
                        )
                      };
                    }
                    return chatRoom;
                  })
                };
              }
              return chatGroup;
            })
          };
        }
        return chatHub;
      });
    },
    inviteMembers: (
      state,
      action: PayloadAction<{ chatHubId: string; users: User[] }>
    ) => {
      state.chatHub = state.chatHub.map((chatHub) => {
        if (chatHub?.id === action.payload.chatHubId) {
          return {
            ...chatHub,
            memberList: chatHub?.memberList?.concat(action.payload.users)
          };
        }
        return chatHub;
      });
    },
    pinMessage: (
      state,
      action: PayloadAction<{
        chatHubId: string;
        chatGroupId: string;
        chatRoomId: string;
        messageId: string;
      }>
    ) => {
      state.chatHub = state.chatHub.map((chatHub) => {
        if (chatHub?.id === action.payload.chatHubId) {
          return {
            ...chatHub,
            chatgroupList: chatHub?.chatgroupList?.map((chatGroup) => {
              if (chatGroup?.id === action.payload.chatGroupId) {
                return {
                  ...chatGroup,
                  chatroomList: chatGroup?.chatroomList?.map((chatRoom) => {
                    if (chatRoom?.id === action.payload.chatRoomId) {
                      return {
                        ...chatRoom,
                        messageList: chatRoom?.messageList?.map((message) => {
                          if (message?.id === action.payload.messageId) {
                            return {
                              ...message,
                              isPinned: !message?.isPinned
                            };
                          }
                          return message;
                        })
                      };
                    }
                    return chatRoom;
                  })
                };
              }
              return chatGroup;
            })
          };
        }
        return chatHub;
      });
    },

    removeMember(
      state,
      action: PayloadAction<{ chatHubId: string; memberId: string }>
    ) {
      state.chatHub = state.chatHub.map((chatHub) => {
        if (chatHub?.id === action.payload.chatHubId) {
          return {
            ...chatHub,
            memberList: chatHub?.memberList?.filter(
              (member) => member?.id !== action.payload.memberId
            )
          };
        }
        return chatHub;
      });
    }
  }
});

export const {
  getChatHub,
  createChatHub,
  addChatGroup,
  deleteChatGroup,
  deleteChatHub,
  createChatRoom,
  editMessage,
  sendMessage,
  inviteMembers,
  deleteMessage,
  removeMember
} = chatHubSlice.actions;
export default chatHubSlice.reducer;
