"use client";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  NormalizedCacheObject,
  split
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";
export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

import {
  ApolloNextAppProvider,
  NextSSRApolloClient,
  NextSSRInMemoryCache,
  SSRMultipartLink
} from "@apollo/experimental-nextjs-app-support/ssr";
import crypto from "crypto";
function sha256(data: string) {
  const hash = crypto.createHash("sha256");
  hash.update(data);
  return hash.digest("hex");
}
function makeClient() {
  const httpLink = ApolloLink.from([
    removeTypenameFromVariables(),
    createPersistedQueryLink({
      sha256: sha256
    }),
    new HttpLink({
      uri: process.env.NEXT_PUBLIC_SERVER_URL ?? ""
    })
  ]);

  const wsLink =
    typeof window !== "undefined"
      ? new GraphQLWsLink(
          createClient({
            url: process.env.NEXT_PUBLIC_SOCKET_URL ?? ""
          })
        )
      : null;
  const splitLink =
    typeof window !== "undefined" && wsLink !== null
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === "OperationDefinition" &&
              definition.operation === "subscription"
            );
          },
          wsLink,
          ApolloLink.from([
            new SSRMultipartLink({
              stripDefer: true
            }),
            httpLink
          ])
        )
      : httpLink;
  return new NextSSRApolloClient({
    ssrMode: typeof window === "undefined",
    link: splitLink,
    cache: new NextSSRInMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            getPostById: {
              read(_, { args, toReference }) {
                return toReference({
                  __typename: "Post",
                  id: args?.id
                });
              }
            },
            getUserById: {
              read(_, { args, toReference }) {
                return toReference({
                  __typename: "User",
                  id: args?.id
                });
              }
            }
          }
        }
      }
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "network-only",
        errorPolicy: "ignore"
      },
      query: {
        fetchPolicy: "network-only",
        errorPolicy: "all"
      }
    }
  });
}

// you need to create a component to wrap your app in
export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  );
}

// export function initializeApollo(
//   initialState: NormalizedCacheObject | null = null
// ) {
//   const _apolloClient = apolloClient || createApolloClient();

//   // If your page has Next.js data fetching methods that use Apollo Client, the initial state
//   // gets hydrated here
//   if (initialState) {
//     // Get existing cache, loaded during client side data fetching
//     const existingCache = _apolloClient.extract();

//     // Merge the existing cache into data passed from getStaticProps/getServerSideProps
//     const data = merge(initialState, existingCache, {
//       // combine arrays using object equality (like in sets)
//       arrayMerge: (destinationArray, sourceArray) => [
//         ...sourceArray,
//         ...destinationArray.filter((d) =>
//           sourceArray.every((s) => !isEqual(d, s))
//         )
//       ]
//     });

//     // Restore the cache with the merged data
//     _apolloClient.cache.restore(data);
//   }

//   // For SSG and SSR always create a new Apollo Client
//   if (typeof window === "undefined") return _apolloClient;

//   // Create the Apollo Client once in the client
//   if (!apolloClient) apolloClient = _apolloClient;

//   return _apolloClient;
// }

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: any
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

// export function useApollo(pageProps: any) {
//   const state = pageProps[APOLLO_STATE_PROP_NAME];
//   return useMemo(() => initializeApollo(state), [state]);
// }
