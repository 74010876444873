"use client";
import { ScrollContext } from "@contexts/ScrollContext";
import { ReactNode, useCallback, useEffect, useState } from "react";

export const HeaderScrollProvider = ({ children }: { children: ReactNode }) => {
  const [y, setY] = useState<number | null>(null);
  const [isScrollUp, setIsScrollUp] = useState<boolean>(false);
  const handleNavigation = useCallback(
    (e: any) => {
      const window = e.currentTarget;
      if (window?.scrollY && y) {
        if (y > window?.scrollY) {
          setIsScrollUp(true);
        } else if (y < window?.scrollY) {
          setIsScrollUp(false);
        }
      }
      setY(window?.scrollY);
    },
    [y]
  );

  useEffect(() => {
    window?.scrollY && setY(window?.scrollY);
    window.addEventListener("scroll", handleNavigation);
    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);
  return (
    <ScrollContext.Provider value={isScrollUp}>
      {children}
    </ScrollContext.Provider>
  );
};
