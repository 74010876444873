"use client";
import { HeaderContext } from "@contexts/toggleFixedHeader";
import { useAppDispatch, useAppSelector } from "@hooks/reduxHooks";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { setUserInfo } from "slices/UserSlice";
const ErrorEffect = dynamic(() => import("./ErrorEffect"), { ssr: false });
import { useTheme } from "next-themes";
import { RootState } from "store";
import Script from "next/script";
import dynamic from "next/dynamic";
import { useSession } from "next-auth/react";
import { useOnlineStatusMutation } from "graqhql-gen/graphql";
const Header = dynamic(() => import("./Header/Header"), {
  ssr: false
  // loading: () => (
  //   <div
  //     className={`h-[5rem] relative top-0 z-[1000] bg-gradient-to-r from-[#227ff2] to-[#6754f7]  dark:from-[#213048] dark:to-[#182c37] duration-200`}
  //   ></div>
  // )
});
const pagesWithHeader = [
  "notification",
  "posts",
  "chat",
  "profile",
  "friends",
  "savedposts",
  "post"
];
interface Props {
  children: ReactNode;
}
const Layout = ({ children }: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isFixedHeader, setFixedHeader] = useState(true);
  const page = usePathname();
  const { data: session, status } = useSession({
    required: true,
    onUnauthenticated() {
      return { redirect: { destination: "/login", permanent: false } };
    }
  });
  const user = useAppSelector((state: RootState) => state.users.user);
  const { setTheme } = useTheme();

  const dispatch = useAppDispatch();
  const [onlineStatus] = useOnlineStatusMutation();
  useEffect(() => {
    if (status === "authenticated" && session?.user && !user.setting) {
      const newSession = {
        ...session.user,
        profileImage: session.user.profileImage
      };
      // TODO: Fix this type
      // @ts-ignore
      dispatch(setUserInfo(newSession));
      setTheme(session?.user?.setting?.theme ?? "system");
      onlineStatus({
        variables: {
          userId: session?.user?.id,
          status: "online"
        }
      })
        .then(() => {})
        .catch((e) => {});
    }
  }, [session]);
  // use effect to set user online status every 30 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      onlineStatus({
        variables: {
          userId: session?.user?.id,
          status: "online"
        }
      })
        .then(() => {})
        .catch((e) => {});
    }, 3000);
    return () => clearInterval(interval);
  }, [session]);
  const headerContextValue = useMemo(
    () => ({ isFixedHeader, setFixedHeader }),
    [isFixedHeader, setFixedHeader]
  );
  return (
    <>
      {/* <Script
        data-ad-client="ca-pub-9551691754766527"
        async
        onError={(e) => {
          console.error("Script failed to load", e);
        }}
        onLoad={() => {
          console.log("Script loaded");
        }}
        onLoadStart={() => {
          console.log("Script is loading");
        }}
        crossOrigin="anonymous"
        strategy="beforeInteractive"
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      /> */}
      {/* <Head>
        <title>
          {`${
            page !== "/"
              ? page
                  .split("/")[1]
                  .replace(/^\w/, (c: string) => c.toUpperCase())
              : "Home"
          } | Social Ground`}
        </title>
        <meta
          name="description"
          content="Social Ground is a social media clone, inspired from various social media websites"
        />
        <meta
          name="google-site-verification"
          content="b_QNKzco6D3zU1-Ok7P2aGli6vyIGI19epl9oMo9wmA"
        />

        <meta
          name="description"
          content="Social Ground is a social media clone, inspired from various social media websites"
        />

        <meta
          property="og:title"
          content={`${
            page !== "/"
              ? page
                  .split("/")[1]
                  .replace(/^\w/, (c: string) => c.toUpperCase())
              : "Home"
          } - Social Ground`}
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="https://res.cloudinary.com/socialground/image/upload/socialground_nogb.png?w=48&h=48"
        />
      </Head> */}
      {(page === "/" || pagesWithHeader.includes(page.split("/")[1])) &&
        status === "authenticated" && (
          <Header
            onPage={page === "/" ? "Home" : page.split("/")[1]}
            isLoading={loading}
          />
        )}
      {children}
    </>
  );
};

export default Layout;
