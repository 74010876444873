import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Post, User } from "graqhql-gen/graphql";
interface PostState {
  posts: Post[];
}
const initialState: PostState = {
  posts: []
};

export const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    getPosts: (state, action: PayloadAction<Post[]>) => {
      if (state.posts && action.payload) state.posts = action.payload;
    },
    fetchMorePosts: (state, action: PayloadAction<Post[]>) => {
      action.payload.forEach((post) => state.posts.push(post));
    },
    writeNewPost: (state, action: PayloadAction<Post>) => {
      state.posts.unshift(action.payload);
    },
    deletePost: (state, action: PayloadAction<string>) => {
      state.posts = state.posts.filter((post) => post.id !== action.payload);
    },
    editPost: (state, action: PayloadAction<Post>) => {
      state.posts = state.posts.map((post) =>
        post.id === action.payload.id ? action.payload : post
      );
    },
    toggleLikePost: (
      state,
      action: PayloadAction<{ postId: string; likedBy: User }>
    ) => {
      state.posts = state.posts.map((post) =>
        post.id === action.payload.postId
          ? {
              ...post,
              likedBy: post.likedBy?.some(
                (user) => user?.id === action.payload.likedBy.id
              )
                ? post.likedBy?.filter(
                    (user) => user?.id !== action.payload.likedBy.id
                  )
                : [...post.likedBy!, action.payload.likedBy as any]
            }
          : post
      );
    }
  }
});
export const {
  writeNewPost,
  toggleLikePost,
  fetchMorePosts,
  deletePost,
  editPost,
  getPosts
} = postSlice.actions;
export default postSlice.reducer;
