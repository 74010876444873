"use client";
import { HeaderScrollProvider } from "@hooks/UseHeaderScroll";
import { KeyboardShortcutProvider } from "@hooks/UseKeyboardShortcut";
import { SessionProvider } from "next-auth/react";
import { ApolloWrapper } from "@libs/ApolloClientWrapper";
import { ThemeProvider } from "next-themes";
import { Provider } from "react-redux";
import { store } from "store";
export default function ClientProviders({
  children
}: {
  children: React.ReactNode;
}) {
  return (
    <ApolloWrapper>
      <Provider store={store}>
        <SessionProvider refetchOnWindowFocus={true}>
          <KeyboardShortcutProvider>
            <HeaderScrollProvider>
              <ThemeProvider attribute="class">{children}</ThemeProvider>
            </HeaderScrollProvider>
          </KeyboardShortcutProvider>
        </SessionProvider>
      </Provider>
    </ApolloWrapper>
  );
}
