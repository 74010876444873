import { createObjectURL } from "blob-util";

export const downloadImageUrl = async (imageUrl: string) => {
  try {
    if (!imageUrl) return "";
    else {
      const image = await fetch(imageUrl);
      const imageBlob = await image.blob();
      return createObjectURL(imageBlob);
    }
  } catch (error) {
    console.log("Error loading image: ", error);
    return "";
  }
};
